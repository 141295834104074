import { PortableText, Button } from '@portfolio/components'
import { LONG_REVALIDATE_TIME } from '@portfolio/configs'
import { ErrorPageResponse } from '@portfolio/models'
import {
  getErrorPage,
  getSiteConfiguration,
} from '@portfolio/services/sanity-service'
import { styled } from '@portfolio/styles'
import { GetStaticProps, NextPage } from 'next'
import { useRouter } from 'next/router'

const Root = styled('div', {
  minHeight: '100vh',
  $py: 'L',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$gray500_01',

  a: {
    whiteSpace: 'nowrap',
    borderRadius: '$rMax',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `fill 200ms, color 200ms, border-color 200ms, background-color 200ms`,
    paddingLeft: '$24',
    paddingRight: '$24',
    height: '$48',

    background: '$gray500',
    color: 'white',
    '&:hover': {
      borderColor: '$gray400',
      background: '$gray400',
      color: 'white',
    },
  },
})
const Content = styled('div', {
  gap: '$32',
  textAlign: 'center',
  maxWidth: '900px',
})
const Title = styled('h1', {
  projectFont: 'display01',
  color: '$gray500',
  marginBottom: '$32',
})
const Description = styled('div', {
  projectFont: 'body02',
  marginBottom: '$32',
  color: '$gray300',
})

const Custom500: NextPage<ErrorPageResponse> = ({ pageData }) => {
  const router = useRouter()
  return (
    <Root>
      <Content>
        {pageData.title && <Title>{pageData.title}</Title>}
        <Description>
          {pageData.description && (
            <PortableText value={pageData.description} />
          )}
        </Description>
        {pageData.ctaLabel && (
          <Button
            size="medium"
            appearance="outlined"
            onClick={() => router.reload()}
          >
            {pageData.ctaLabel}
          </Button>
        )}
      </Content>
    </Root>
  )
}

export const getStaticProps: GetStaticProps<ErrorPageResponse> = async ({
  locale,
  preview = false,
}) => {
  const siteConfiguration = await getSiteConfiguration(locale, preview)
  const pageData = await getErrorPage(locale, preview)

  return {
    props: {
      siteConfiguration,
      showFooter: true,
      pageData,
    },
    revalidate: LONG_REVALIDATE_TIME,
  }
}

export default Custom500
